import { Typography } from '@mui/material';

import { Formik } from 'formik';
import { CustomItemReportModalOptions } from 'types/Reports';
import { ModalDownloadButtons } from './ModalDownloadButtons';
import { useItemReport } from '../hooks/useItemReport';
import { ItemSelector } from 'components/ItemSelector/ItemSelector';
import { useState } from 'react';
import { GenericExportDialog } from './GenericExportDialog';

export const CustomItemReportModal = () => {
  const { isItemReportModalOpen, setIsItemReportModalOpen, isDownloading, download } =
    useItemReport();

  const [errorMessage, setErrorMessage] = useState('');

  const handleOnClose = () => {
    setIsItemReportModalOpen(false);
  };

  const handleSubmit = (values: CustomItemReportModalOptions) => {
    if (values.item) {
      download(values.item, { type: values.type });
    } else {
      setErrorMessage('Item Required');
    }
  };

  return (
    <GenericExportDialog
      title="Item Report"
      open={isItemReportModalOpen}
      onClose={handleOnClose}
    >
      <Formik
        initialValues={{
          item: null,
          type: 'pdf',
        }}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, setFieldValue }) => {
          return (
            <form>
              <Typography
                variant="textDefaultSemiBold"
                sx={{ my: 0.5, display: 'block' }}
              >
                Select an item to download
              </Typography>

              <ItemSelector
                item={values.item}
                setItem={(item) => {
                  setErrorMessage('');
                  setFieldValue('item', item);
                }}
                error={errorMessage}
              />
              <ModalDownloadButtons
                handleSubmit={handleSubmit}
                isDownloading={isDownloading}
                types={['pdf', 'xlsx']}
                values={values}
              />
            </form>
          );
        }}
      </Formik>
    </GenericExportDialog>
  );
};
