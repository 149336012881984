import { MenuItem, Box, Menu, SxProps, Theme } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronUp,
  faFileExcel,
  faFileExport,
  faFilePdf,
} from '@fortawesome/pro-regular-svg-icons';
import { BaseSyntheticEvent, MouseEvent, ReactNode, useState } from 'react';
import { ReportFileType } from 'types/Reports';
import { LoadingButton } from '@mui/lab';

type ExportButtonProps = {
  onClick: (e: MouseEvent, type: ReportFileType) => void;
  supportedExports?: ReportFileType[];
  customExports?: ({ close }: { close: () => void }) => ReactNode;
  sx?: SxProps<Theme>;
  disabled?: boolean;
  loading?: boolean;
};

export const ExportButton = ({
  onClick,
  supportedExports = ['pdf', 'xlsx'],
  customExports,
  sx,
  disabled,
  loading,
}: ExportButtonProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = !!anchorEl;
  const handleClose = () => setAnchorEl(null);
  const handleClick = (event: BaseSyntheticEvent) => setAnchorEl(event.currentTarget);

  return (
    <>
      <LoadingButton
        sx={sx}
        variant="greyGhost"
        onClick={handleClick}
        title="Export"
        startIcon={<FontAwesomeIcon icon={faFileExport} />}
        endIcon={<FontAwesomeIcon icon={open ? faChevronUp : faChevronDown} />}
        disabled={disabled}
        loading={loading}
        data-testid="export_button"
      />
      <Menu
        id="export-actions-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'export-actions-menu',
        }}
      >
        {supportedExports?.includes('pdf') ? (
          <MenuItem
            onClick={(e) => {
              onClick(e, 'pdf');
              handleClose();
            }}
          >
            <Box
              sx={{ minWidth: 14, pb: '2px', mr: 1 }}
              component={FontAwesomeIcon}
              icon={faFilePdf}
            />
            PDF File
          </MenuItem>
        ) : null}

        {supportedExports?.includes('xlsx') ? (
          <MenuItem
            onClick={(e) => {
              onClick(e, 'xlsx');
              handleClose();
            }}
          >
            <Box
              sx={{ minWidth: 14, pb: '2px', mr: 1 }}
              component={FontAwesomeIcon}
              icon={faFileExcel}
            />
            Excel File
          </MenuItem>
        ) : null}
        {customExports ? customExports({ close: handleClose }) : null}
      </Menu>
    </>
  );
};
