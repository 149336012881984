import { Box, SxProps, Theme, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faClock, faDiamond } from '@fortawesome/pro-solid-svg-icons';
import { joinSx } from 'utils/helpers';

type OptionLabelWithInfoProps = {
  option: { value: number; label: string; hasEstimate: boolean };
  forceInfo?: boolean;
  sx?: SxProps<Theme>;
};

export const OptionLabelWithInfo = ({
  option,
  forceInfo,
  sx,
}: OptionLabelWithInfoProps) => {
  return (
    <Box
      sx={joinSx(sx, {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        '&:hover .statusInfo': {
          visibility: 'visible',
        },
        gap: 1,
      })}
    >
      <Box
        component={FontAwesomeIcon}
        icon={faDiamond}
        sx={{
          color: 'grey.500',
        }}
      />
      <Typography
        sx={{
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      >
        {option.label}
      </Typography>
      <Typography
        className="statusInfo"
        sx={{
          color: option.hasEstimate ? 'successGreen.700' : 'categoricYellow.800',
          ml: 'auto',
          fontStyle: 'italic',
          visibility: forceInfo ? 'visible' : 'hidden',
        }}
      >
        {option.hasEstimate ? 'has estimate' : 'pending estimate'}
      </Typography>

      <Box
        component={FontAwesomeIcon}
        icon={option.hasEstimate ? faCircleCheck : faClock}
        sx={{
          pr: 1.75,
          color: option.hasEstimate ? 'successGreen.700' : 'categoricYellow.400',
        }}
      />
    </Box>
  );
};
