import { Typography } from '@mui/material';

import { DesignMilestoneSelector } from 'components/DesignMilestoneSelector';
import { useActiveMilestone } from 'features/Estimate/hooks/milestone';
import { Formik } from 'formik';
import { CustomForesiteReportModalOptions } from 'types/Reports';
import * as Yup from 'yup';
import { useEstimateReport } from '../hooks/useEstimateReport';
import { ModalDownloadButtons } from './ModalDownloadButtons';
import { GenericExportDialog } from './GenericExportDialog';

const validationSchema = Yup.object({
  milestone: Yup.object().required('Milestone Required'),
});

export const CustomSubtotalsReportModal = () => {
  const {
    isFullEstimateReport,
    isEstimateReportModalOpen,
    setIsEstimateReportModalOpen,
    isDownloading,
    download,
  } = useEstimateReport();

  const { activeMilestone } = useActiveMilestone();

  const handleOnClose = () => {
    setIsEstimateReportModalOpen(false);
  };

  const handleSubmit = (values: CustomForesiteReportModalOptions) => {
    download(values.milestone!, {
      type: values.type,
      per_component: isFullEstimateReport,
    });
  };

  return (
    <GenericExportDialog
      title={
        isFullEstimateReport ? 'Download Estimate Report' : 'Download Milestone Report'
      }
      open={isEstimateReportModalOpen}
      onClose={handleOnClose}
    >
      <Formik
        initialValues={{
          milestone: activeMilestone,
          type: 'pdf',
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnBlur
        enableReinitialize
      >
        {({ values, setFieldValue }) => {
          return (
            <form noValidate>
              <Typography sx={{ ml: 0.5, my: 0.5 }}>Pick a Milestone</Typography>

              <DesignMilestoneSelector
                data-testid="item-selector"
                milestone={values.milestone}
                setMilestone={(milestone) => setFieldValue('milestone', milestone)}
                onlyWithEstimates
              />
              <ModalDownloadButtons
                handleSubmit={handleSubmit}
                isDownloading={isDownloading}
                types={isFullEstimateReport ? ['xlsx'] : ['pdf', 'xlsx']}
                values={values}
              />
            </form>
          );
        }}
      </Formik>
    </GenericExportDialog>
  );
};
