import { useState } from 'react';

export const useFeatureFlags = () => {
  const [isNotificationEnabled] = useState(
    import.meta.env.VITE_FEATUREFLAG_NOTIFICATION?.toLowerCase() === 'true',
  );
  const [isCustomNormalizationEnabled] = useState(
    import.meta.env.VITE_FEATUREFLAG_CUSTOM_NORMALIZATION?.toLowerCase() === 'true',
  );
  const [isForeSiteChartZoomEnabled] = useState(
    import.meta.env.VITE_FEATUREFLAG_FORESITE_CHART_ZOOM?.toLowerCase() === 'true',
  );
  const [isCustomCodesEnabled] = useState(
    import.meta.env.VITE_FEATUREFLAG_CUSTOM_CODES?.toLowerCase() === 'true',
  );
  const [isBidPackageDetailsEnabled] = useState(
    import.meta.env.VITE_FEATUREFLAG_PROCUREMENT_BID_PACKAGE_DETAILS_ENABLED?.toLowerCase() ===
      'true',
  );
  const [isReportingEnabled] = useState(
    import.meta.env.VITE_FEATUREFLAG_REPORTING?.toLowerCase() === 'true',
  );
  const [isPWAEnabled] = useState(
    import.meta.env.VITE_FEATUREFLAG_PWA?.toLowerCase() === 'true',
  );

  const [isTVDMFReportEnabled] = useState(
    import.meta.env.VITE_FEATUREFLAG_TVD_MF_REPORT_ENABLED?.toLowerCase() === 'true',
  );
  const [isContextEnabled] = useState(
    import.meta.env.VITE_FEATUREFLAG_CONTEXT?.toLowerCase() === 'true',
  );

  const isIssueManagementEnabled =
    import.meta.env.VITE_FEATUREFLAG_ISSUE_MANAGEMENT?.toLowerCase() === 'true';
  const flags = {
    isNotificationEnabled,
    isCustomNormalizationEnabled,
    isForeSiteChartZoomEnabled,
    isCustomCodesEnabled,
    isBidPackageDetailsEnabled,
    isPWAEnabled,
    isReportingEnabled,
    isTVDMFReportEnabled,
    isContextEnabled,
    isIssueManagementEnabled,
  };

  return flags;
};

export type FeatureFlagsKeys = keyof ReturnType<typeof useFeatureFlags>;
