import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ApiService } from 'api/ApiService';
import { Resources } from 'api/Resources';
import { User } from 'types/User';
import log from 'loglevel';
import { queryKeys } from 'utils/reactQuery';

export const useCompanyUsers = () => {
  const companyUsersQuery = useQuery({
    queryKey: queryKeys.companyUsers,
    queryFn: ({ signal }) => {
      return ApiService.get(Resources.COMPANY_USERS, { signal }).then(
        (res) => res.data as User[],
      );
    },
    staleTime: Infinity,
  });
  return { companyUsersQuery };
};

export const useDeleteUser = () => {
  const queryClient = useQueryClient();

  const deleteUserMutation = useMutation({
    mutationFn: ({ userId }: { userId: number; onSuccessCallback: () => void }) => {
      const endPoint = Resources.COMPANY_USER.replace('<int:user_id>', `${userId}`);

      return ApiService.delete(endPoint);
    },
    onMutate: async ({ userId }) => {
      await queryClient.cancelQueries({ queryKey: queryKeys.companyUsers });

      // invalidate all project members queries
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === 'project' && query.queryKey[2] === 'members',
      });
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === 'project' && query.queryKey[2] === 'moduleMembers',
      });
      const previousCompanyUsers = queryClient.getQueryData(queryKeys.companyUsers);
      if (previousCompanyUsers) {
        queryClient.setQueryData(queryKeys.companyUsers, (oldCompanyUsers?: User[]) =>
          oldCompanyUsers?.filter((oldCompanyUser) => oldCompanyUser.id !== userId),
        );
      }
      return previousCompanyUsers;
    },
    onError: (error, _userId, previousCompanyUsers) => {
      if (previousCompanyUsers) {
        queryClient.setQueryData(queryKeys.companyUsers, previousCompanyUsers);
      }
      log.error(error instanceof Error ? error.message : error);
    },
    onSuccess: (_response, { onSuccessCallback }) => {
      onSuccessCallback();
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.companyUsers });
      queryClient.invalidateQueries({ queryKey: queryKeys.permissionRoles });
    },
  });

  return { deleteUserMutation };
};

export const useResendInviteUser = () => {
  const resendInviteMutation = useMutation({
    mutationFn: ({ userId }: { userId: number }) => {
      const endPoint = Resources.COMPANY_USER_RESEND_INVITE.replace(
        '<int:user_pk>',
        `${userId}`,
      );
      return ApiService.post(endPoint);
    },
  });

  return { resendInviteMutation };
};

export const useBatchAssignProjects = () => {
  const queryClient = useQueryClient();
  const batchAssignProjectsMutation = useMutation({
    mutationFn: ({ projects, user }: { projects: number[]; user: number }) => {
      const endPoint = Resources.COMPANY_USER_ASSIGN_PROJECTS.replace(
        '<int:user_pk>',
        `${user}`,
      );
      return ApiService.post(endPoint, { projects });
    },
    onSettled: (_data, _error_) => {
      queryClient.invalidateQueries({ queryKey: queryKeys.companyUsers });
    },
  });

  return { batchAssignProjectsMutation };
};
