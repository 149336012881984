import { memo, useCallback, useEffect, useState } from 'react';
import MuiDrawer from '@mui/material/Drawer';
import { IconButton, List, Tooltip, styled, useTheme, Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';

import { CurrentProjectItem } from './CurrentProjectItem';
import { NavigationLinkItems } from './NavigationLinkItems';
import { useSidebar } from 'hooks/ui';
import { LogoListItem } from './LogoListItem';
import { isEmpty } from 'lodash-es';
import { useQueryParam } from 'hooks/useQueryParam';
import { useSelectedProjectId } from 'features/Projects/hook/useSelectedProjectId';
import { useProject } from 'features/Projects/hook/useProject';
import { useAllProjectSectionsEnabled } from 'hooks/useAllProjectSectionsEnabled';
import { useDebouncedCallback } from 'use-debounce';
import { FooterListItem } from './FooterListItem';

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({
  theme,
  open,
}) => {
  const { sidebarWidth } = useSidebar();

  return {
    '& .MuiDrawer-paper': {
      borderRadius: 0,
      border: 0,
      position: 'relative',
      whiteSpace: 'nowrap',
      width: sidebarWidth + '!important',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  };
});

export const SideBar = () => {
  const { project, isProjectLoading, isProjectFetching } = useProject();
  const { selectedProjectId } = useSelectedProjectId();
  const { setSidebarState } = useSidebar();

  const theme = useTheme();
  const lgBreakpoint = theme.breakpoints.values.xl;

  const query = useQueryParam<{ showStepper?: string }>();
  const showStepperQueryParam = query?.showStepper;
  const isStepperComplete = useAllProjectSectionsEnabled();

  const isProjectLoadingFetching = isProjectLoading || isProjectFetching;
  const statusShouldHaveDrawerHidden =
    !selectedProjectId || isEmpty(project) || !isStepperComplete;

  const updateSidebarState = useCallback(() => {
    if (isProjectLoadingFetching) return;

    if (statusShouldHaveDrawerHidden || showStepperQueryParam) {
      setSidebarState('hidden');
      return;
    }

    const shouldCollapseSidebarIfPossible = window.innerWidth <= lgBreakpoint;
    if (shouldCollapseSidebarIfPossible) {
      setSidebarState('collapsed');
    } else {
      setSidebarState('expanded');
    }
  }, [
    isProjectLoadingFetching,
    lgBreakpoint,
    setSidebarState,
    showStepperQueryParam,
    statusShouldHaveDrawerHidden,
  ]);
  const debouncedUpdateSidebarState = useDebouncedCallback(updateSidebarState, 400);

  useEffect(() => {
    updateSidebarState();
    window.addEventListener('resize', debouncedUpdateSidebarState);
    return () => {
      window.removeEventListener('resize', debouncedUpdateSidebarState);
    };
  }, [debouncedUpdateSidebarState, updateSidebarState]);

  return <SidebarContent />;
};

const SidebarContentCmp = () => {
  const [isDrawerButtonVisible, setIsDrawerButtonVisible] = useState(false);
  const { isSidebarCollapsed, toggleSidebar, sidebarWidth, isSidebarHidden } =
    useSidebar();

  return (
    <>
      <Tooltip
        title={!isSidebarCollapsed ? 'Collapse' : 'Expand'}
        placement="right"
        arrow
      >
        <IconButton
          size="small"
          onClick={() => toggleSidebar()}
          sx={[
            {
              position: isSidebarHidden ? 'absolute' : 'fixed',
              left: `calc(${sidebarWidth} - 0.75rem)`,
              top: (theme) => `calc(${theme.navBarHeight} + .5rem)`,
              width: '1.5rem',
              height: '1.5rem',
              zIndex: 1300,
              display: 'block',
              padding: '0',
              backgroundColor: 'common.white',
              borderStyle: 'solid',
              borderWidth: '1px',
              borderColor: (theme) => theme.palette.grey[200],
              transition: (theme) =>
                theme.transitions.create('left', {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.leavingScreen,
                }),
              '&:hover': {
                backgroundColor: 'primary.main',
                color: 'common.white',
              },
              '& .svg-inline--fa': {
                height: '16px',
              },
            },

            !isDrawerButtonVisible && {
              visibility: 'hidden',
            },
          ]}
          onMouseOver={() => setIsDrawerButtonVisible(true)}
          onMouseOut={() => setIsDrawerButtonVisible(false)}
        >
          <FontAwesomeIcon icon={!isSidebarCollapsed ? faChevronLeft : faChevronRight} />
        </IconButton>
      </Tooltip>
      <Drawer
        data-testid="project-sidebar"
        variant="permanent"
        open={!isSidebarCollapsed}
        onMouseOver={() => setIsDrawerButtonVisible(true)}
        onMouseOut={() => setIsDrawerButtonVisible(false)}
      >
        <List
          component="nav"
          sx={{
            color: 'grey.50',
            background: 'linear-gradient(161.05deg, #495164 0%, #2F3340 79.08%)',
            height: '100vh',
            position: isSidebarHidden ? 'relative' : 'fixed',
            width: sidebarWidth,
            transition: 'width 0.2s',
            overflow: 'hidden',
          }}
        >
          <LogoListItem />
          <CurrentProjectItem />
          <Box
            data-testid="sidebar-navigation-link-items"
            sx={{ overflowY: 'auto', height: `calc(100% - 94px)` }}
          >
            <NavigationLinkItems />
            <FooterListItem />
          </Box>
        </List>
      </Drawer>
    </>
  );
};

const SidebarContent = memo(SidebarContentCmp);
