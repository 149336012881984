import { SetStateAction, useCallback, useState } from 'react';

type Value = string | number | boolean;

export function useLocalStorage<T extends Value>(key: string, initialValue: T) {
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }
    try {
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that persists
  // the new value to localStorage.
  const setValue = useCallback(
    (value: SetStateAction<T> | T) => {
      try {
        // Allow value to be a function so we have same API as useState
        const valueToStore = value instanceof Function ? value(storedValue) : value;
        setStoredValue(valueToStore);
        if (typeof window !== 'undefined') {
          window.localStorage.setItem(key, JSON.stringify(valueToStore));
        }
      } catch (error) {}
    },
    [key, storedValue],
  );
  return [storedValue, setValue] as const;
}
