import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';

export function FlyoutDialogTitleIcon({ icon }: { icon: IconDefinition }) {
  return (
    <Box
      display="inline-flex"
      flexDirection="column"
      justifyContent="center"
      sx={{
        backgroundColor: 'blue.100',
        color: 'blue.700',
        width: '32px',
        height: '32px',
        borderRadius: '50%',
        padding: '3px',
        borderStyle: 'solid',
        borderWidth: '2px',
        borderColor: 'blue.200',
        textAlign: 'center',
        mr: 1,
      }}
    >
      <FontAwesomeIcon size="xs" icon={icon} />
    </Box>
  );
}
