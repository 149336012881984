import { faCog } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, MenuItem } from '@mui/material';
import { useProject } from 'features/Projects/hook/useProject';
import log from 'loglevel';
import { ReportItem } from 'types/Reports';
import { ExportButton } from 'components/ExportButton/ExportButton';
import { useState } from 'react';

type ReportDownloadButtonsProps = {
  report: ReportItem;
};

export const ReportDownloadButtons = ({ report }: ReportDownloadButtonsProps) => {
  const { project } = useProject();
  const [isDownloading, setIsDownloading] = useState(false);

  return (
    <Box display={'flex'} gap={2}>
      <ExportButton
        supportedExports={report.formats}
        onClick={(_, format) => {
          setIsDownloading(true);
          report
            .onDownload(project!, { type: format })
            .then(() => setIsDownloading(false))
            .catch((error) => log.error(error instanceof Error ? error.message : error));
        }}
        disabled={isDownloading}
        loading={isDownloading}
        customExports={
          report.openCustomReportModal
            ? ({ close }) => {
                return (
                  <MenuItem
                    onClick={() => {
                      report.openCustomReportModal?.(true);
                      close();
                    }}
                    disabled={isDownloading}
                  >
                    <Box
                      sx={{ minWidth: 14, pb: '2px', mr: 1 }}
                      component={FontAwesomeIcon}
                      icon={faCog}
                      data-testid="custom-report-option"
                    />
                    Custom Report
                  </MenuItem>
                );
              }
            : undefined
        }
      />
    </Box>
  );
};
