import {
  Box,
  Button,
  Stack,
  styled,
  useMediaQuery,
  Theme,
  SxProps,
  IconButton,
  Tooltip,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useMixpanel } from 'hooks/useMixpanel';
import { MouseEventHandler, ReactNode } from 'react';
import { useSidebar } from 'hooks/ui';
import { circleButtonStyles } from './styles';

const StyledButton = styled(Button)<{
  // fix for `component` typing
  // see: https://github.com/mui/material-ui/issues/13921
  component: React.ElementType;
  to: string;
}>(({ theme }) => ({
  color: theme.palette.grey[500] + '!important',
  padding: '6px 16px',
  border: 'none',

  '&:hover': {
    backgroundColor: theme.palette.grey[100],
  },
  '&:focus': {
    boxShadow: 'none',
  },
}));

StyledButton.defaultProps = {
  variant: 'greyGhost',
};

type NavBarButtonProps = {
  isActive?: boolean;
  to: string;
  icon: ReactNode;
  children: string;
  sx?: SxProps<Theme>;
  onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
};

export const NavBarButton = (props: NavBarButtonProps) => {
  const { isActive, onClick, children, icon, sx, to } = props;
  const { isSidebarHidden } = useSidebar();
  const { mixpanel } = useMixpanel();
  const shouldShowLabel =
    useMediaQuery((theme: Theme) => theme.breakpoints.up('lg')) || isSidebarHidden;

  const handleOnClick: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement> = (e) => {
    mixpanel.click(children, 'navigation bar');
    onClick?.(e);
  };

  return shouldShowLabel ? (
    <Stack
      sx={[
        !!isActive && {
          '& .MuiButton-startIcon': {
            color: 'primary.main',
          },
        },
      ]}
    >
      <StyledButton
        component={Link}
        startIcon={icon}
        sx={sx}
        to={to}
        onClick={handleOnClick}
      >
        {children}
      </StyledButton>
      {isActive && (
        <Box
          sx={(theme) => ({
            height: '4px',
            backgroundColor: theme.palette.primary.main,
            borderTopLeftRadius: '4px',
            borderTopRightRadius: '4px',
            mt: '4px',
            mb: '-8px',
          })}
        />
      )}
    </Stack>
  ) : (
    <Tooltip title={children} placement="bottom">
      <IconButton
        sx={[
          circleButtonStyles,
          ...(Array.isArray(sx) ? sx : [sx]),
          {
            color: (theme) =>
              isActive ? 'inherit' : `${theme.palette.grey[500]} !important`,
          },
        ]}
        onClick={handleOnClick}
        component={Link}
        to={to}
      >
        {icon}
      </IconButton>
    </Tooltip>
  );
};
