/* eslint-disable react-refresh/only-export-components */
import { Component } from 'react';
import ReactGA from 'react-ga';
import { Route } from 'react-router-dom';
import { isProd, isTesting } from 'utils/environment';

type GoogleAnalyticsProps = {
  location: {
    pathname: string;
    search: string;
  };
  options: object;
};

class GoogleAnalytics extends Component<GoogleAnalyticsProps> {
  componentDidMount() {
    this.logPageChange(this.props.location.pathname, this.props.location.search);
  }

  componentDidUpdate({ location: prevLocation }: GoogleAnalyticsProps) {
    const {
      location: { pathname, search },
    } = this.props;
    const isDifferentPathname = pathname !== prevLocation.pathname;
    const isDifferentSearch = search !== prevLocation.search;

    if (isDifferentPathname || isDifferentSearch) {
      this.logPageChange(pathname, search);
    }
  }

  logPageChange(pathname: string, search = '') {
    const page = pathname + search;
    const { location } = window;
    ReactGA.set({
      page,
      location: `${location.origin}${page}`,
      ...this.props.options,
    });
    ReactGA.pageview(page);
  }

  render() {
    return null;
  }
}

const RouteTracker = () => <Route component={GoogleAnalytics} />;

const init = (_options = {}) => {
  const isGATesting = isTesting();
  const isGAProduction = isProd();

  // GA for testing env
  if (isGATesting) {
    ReactGA.initialize('UA-153102032-1');
  }

  // GA for production env
  if (isGAProduction) {
    ReactGA.initialize('UA-153102032-2');
  }

  return true;
};

export default {
  GoogleAnalytics,
  RouteTracker,
  init,
};
