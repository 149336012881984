import { addSearchParamsToUrl } from 'utils/helpers';
import { History } from 'Urls';
import queryString from 'query-string';
import { isEqual } from 'lodash-es';
import { useCallback } from 'react';
import { PrimitiveAtom, atom, useAtom } from 'jotai';

export const BooleanQueryParamValues = ['true', 'false'] as const;

export type BooleanQueryParam = (typeof BooleanQueryParamValues)[number];

type UseQueryParamStateParams<T> = {
  name: string;
  defaultValue?: T;
  validate?: (value?: T | null) => boolean;
};

// This atoms are to workaround that changing the query params is not re-rendering the parent component that uses this hook.
// Every time a query param changes we update this atom so all the hook instances react and their parent re-render.
// Note that even though we store the param values here we still rely on the query param value as the source of truth.
export const queryParamAtoms: Record<string, PrimitiveAtom<string>> = {};
const getQueryParamAtom = (name: string) => {
  if (!queryParamAtoms[name]) {
    queryParamAtoms[name] = atom('');
  }
  return queryParamAtoms[name];
};

export const useQueryParamState = <T extends string | number | BooleanQueryParam>(
  params: UseQueryParamStateParams<T>,
) => {
  const [_, setQueryParamValue] = useAtom(getQueryParamAtom(params.name));
  const useSetQueryParamValue = () => {
    const setValue = useCallback(
      (value: T) => {
        const { pathname, search } = History.location;

        const query = queryString.parse(search || '') as Record<string, string>;

        const searchParams = {
          ...query,
          [params.name]: value,
        };

        // We don't set the defaultValue into the URL as query string
        if (value === params.defaultValue) {
          // If the query string name is not there, there is nothing to do
          if (query[params.name] == null) {
            return;
          } else {
            // If the query string name is there, we remove it from the URL
            delete searchParams[params.name];
          }
        }

        const url = addSearchParamsToUrl({
          url: pathname,
          searchParams,
        });

        if (!isEqual(searchParams, query)) {
          History.push(url);
          setQueryParamValue(value?.toString());
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [params.defaultValue, params.name],
    );

    return setValue;
  };
  const setValue = useSetQueryParamValue();
  const query = queryString.parse(History.location?.search || '');
  const queryParamValue = query[params.name] as T;
  const value =
    !params.validate || params.validate(queryParamValue)
      ? queryParamValue
      : params.defaultValue;
  if (value !== queryParamValue) {
    setValue(value as T);
  }

  return [value, setValue] as [T, (value: T | undefined) => void];
};
