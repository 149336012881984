import { Box, Checkbox, FormHelperText, Stack, Typography } from '@mui/material';
import { useProject } from 'features/Projects/hook/useProject';
import { useForesiteReport } from '../hooks/useForesiteReport';
import { DesignMilestoneSelector } from 'components/DesignMilestoneSelector';
import { Formik } from 'formik';
import { CustomForesiteReportModalOptions } from 'types/Reports';
import { ModalDownloadButtons } from './ModalDownloadButtons';
import { useForesiteReportInfo } from '../hooks/useForesiteReportInfo';
import { useState } from 'react';
import { GenericExportDialog } from './GenericExportDialog';

export const CustomForesiteReportModal = () => {
  const {
    isForesiteReportModalOpen,
    setIsForesiteReportModalOpen,
    isDownloading,
    download,
  } = useForesiteReport();
  const { project } = useProject();
  const { hasItemReportInfo, isLoading: isChecking } = useForesiteReportInfo();
  const [hasData, setHasData] = useState(true);

  const handleOnClose = () => {
    setIsForesiteReportModalOpen(false);
  };

  const handleSubmit = async (values: CustomForesiteReportModalOptions) => {
    download(project!, values);
  };

  return (
    <GenericExportDialog
      title="Custom Items Report"
      open={isForesiteReportModalOpen}
      onClose={handleOnClose}
    >
      <Formik
        initialValues={{
          includeDeleteItems: true,
          includeComments: true,
          milestone: null,
          type: 'pdf',
        }}
        onSubmit={handleSubmit}
        validate={async (values) => {
          const data = await hasItemReportInfo(values);
          setHasData(Boolean(data.has_data));
          return {};
        }}
      >
        {({ values, setFieldValue }) => {
          return (
            <form noValidate>
              <Typography sx={{ ml: 0.5, my: 0.5 }}>
                Filter your Items by their Design Milestone
              </Typography>

              <DesignMilestoneSelector
                milestone={values.milestone}
                setMilestone={(milestone) => setFieldValue('milestone', milestone)}
                allowNoMilestone
              />
              {!hasData && (
                <FormHelperText sx={{ color: 'error.main' }}>
                  The report will be empty please select other filters
                </FormHelperText>
              )}

              <Stack sx={{ height: '210px', mt: 2 }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Checkbox
                    checked={values.includeDeleteItems}
                    onChange={() =>
                      setFieldValue('includeDeleteItems', !values.includeDeleteItems)
                    }
                    size="small"
                  />
                  <Typography sx={{ mr: 'auto' }}>
                    Include deleted items in the report
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Checkbox
                    checked={values.includeComments}
                    onChange={() =>
                      setFieldValue('includeComments', !values.includeComments)
                    }
                    size="small"
                  />
                  <Typography sx={{ mr: 'auto' }}>
                    Include comments in the report
                  </Typography>
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                  <ModalDownloadButtons
                    handleSubmit={handleSubmit}
                    isDownloading={isDownloading || isChecking}
                    types={['pdf', 'xlsx']}
                    values={values}
                  />
                </Box>
              </Stack>
            </form>
          );
        }}
      </Formik>
    </GenericExportDialog>
  );
};
