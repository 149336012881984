import { AxiosRequestConfig } from 'axios';
import { CustomForesiteReportModalOptions } from 'types/Reports';

export const config = {
  headers: {
    'content-type': 'multipart/form-data',
  },
  responseType: 'blob',
} satisfies AxiosRequestConfig;

export const getForesiteSearchParams = (options: CustomForesiteReportModalOptions) => {
  const searchParams = {
    type: options.type ?? 'pdf',
    excluded_deleted_items: !options.includeDeleteItems,
    milestone: options.milestone?.id ?? '',
    include_comments: options.includeComments,
  };

  return searchParams;
};
