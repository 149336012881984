import { Box, Collapse, Toolbar, styled, Theme } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBook,
  faBookOpen,
  faBriefcase,
  faGrid2,
} from '@fortawesome/pro-regular-svg-icons';

import concntricLogo from 'assets/img/concntric-logo-light.svg';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { PrivateURL } from 'Urls';
import { NewProjectButton } from './NewProjectButton';
import { SettingsIconButton } from './SettingsIconButton';
import usePermissions from 'features/Auth/hook/usePermissions';
import { useShowStepper, useSidebar } from 'hooks/ui';
import { NotificationButton } from './NotificationButton';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { UserIconButton } from 'components/UserIconButton/UserIconButton';
import { HelpIconButton } from './HelpIconButton';
import { NavBarButton } from 'components/NavBarButton/NavBarButton';
import { circleButtonStyles } from 'components/NavBarButton/styles';
import { HasRoutePerm } from 'features/Auth/components/HasRoutePerm';
import { useTenantFeatureFlags } from 'hooks/useTenantFeatureFlags';

const AppBar = styled(MuiAppBar)(({ theme }) => {
  const { sidebarWidth } = useSidebar();

  return {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: sidebarWidth,
    width: `calc(100% - ${sidebarWidth})`,
    border: 'none',
    backgroundColor: theme.palette.grey[600] + '!important',
    borderRadius: 'unset',
  };
});

export const NavBar = () => {
  const { HasPerm, Permissions } = usePermissions();
  const { isSidebarHidden } = useSidebar();
  const location = useLocation();
  const { isNotificationEnabled } = useFeatureFlags();
  const showStepper = useShowStepper();
  const {
    tenantFeatureFlags: { markup_templates: isMarkupTemplatesEnabled },
  } = useTenantFeatureFlags();

  const isActive = (path?: string) =>
    !!path &&
    matchPath(location.pathname, {
      path,
      exact: true,
      strict: false,
    })?.isExact;

  const areActionsEnabled = !showStepper;

  return (
    <AppBar position="fixed">
      <Toolbar
        data-testid="toolbar-navigation"
        sx={{
          height: (theme: Theme) => theme.navBarHeight,
          minHeight: (theme: Theme) => theme.navBarHeight + ' !important',
          backgroundColor: 'white',
          color: 'primary.main',
          borderTopLeftRadius: isSidebarHidden ? 0 : '10px',
        }}
      >
        <Collapse orientation="horizontal" in={isSidebarHidden}>
          <Link to={PrivateURL.PROJECT_LIST}>
            <Box
              component="img"
              src={concntricLogo}
              alt="Small Concentric logo"
              sx={{ height: '2rem', mr: '6.5rem', mt: 0.5 }}
            />
          </Link>
        </Collapse>
        {areActionsEnabled ? (
          <HasRoutePerm>
            <NavBarButton
              to={PrivateURL.PROJECT_LIST}
              icon={<FontAwesomeIcon icon={faGrid2} />}
              isActive={isActive(PrivateURL.PROJECT_LIST)}
            >
              Projects
            </NavBarButton>
            <HasPerm permission={Permissions.PORTFOLIO_ACCESS}>
              <NavBarButton
                to={PrivateURL.PORTFOLIO}
                icon={<FontAwesomeIcon icon={faBriefcase} />}
                isActive={isActive(PrivateURL.PORTFOLIO)}
              >
                Portfolio
              </NavBarButton>
            </HasPerm>
            <HasPerm permission={Permissions.IDEA_ACCESS}>
              <NavBarButton
                to={PrivateURL.FORESITE_LIBRARY}
                icon={<FontAwesomeIcon icon={faBookOpen} />}
                isActive={isActive(PrivateURL.FORESITE_LIBRARY)}
              >
                Library
              </NavBarButton>
            </HasPerm>
            {isMarkupTemplatesEnabled && (
              <HasPerm permission={Permissions.MARKUP_TEMPLATES_ACCESS}>
                <NavBarButton
                  to={PrivateURL.MARKUP_TEMPLATES}
                  icon={<FontAwesomeIcon icon={faBook} />}
                  isActive={isActive(PrivateURL.MARKUP_TEMPLATES)}
                >
                  Markup Templates
                </NavBarButton>
              </HasPerm>
            )}
            <Box
              sx={{
                marginLeft: 'auto',
                display: 'flex',
                padding: `2px 0`,
                alignItems: 'center',
              }}
            >
              <NewProjectButton sx={{ height: '2rem' }} />
              <SettingsIconButton
                sx={[
                  circleButtonStyles,
                  {
                    marginLeft: (theme: Theme) => theme.spacing(1),
                  },
                ]}
              />
              <HelpIconButton sx={circleButtonStyles} />
              {isNotificationEnabled ? (
                <NotificationButton sx={circleButtonStyles} />
              ) : null}
              <UserIconButton sx={circleButtonStyles} />
            </Box>
          </HasRoutePerm>
        ) : (
          <Box
            sx={{
              marginLeft: 'auto',
            }}
          >
            <UserIconButton sx={circleButtonStyles} readOnly />
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};
